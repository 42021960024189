// import furnituresArray from "./furnitureArray.js";
// import GUI from "lil-gui";
// import {interest} from "@/Data.js";

import Swiper from "swiper";
import {Navigation} from "swiper/modules";

export default class Sliders {
	constructor(world) {
		this.world = world;
		this.containers = document.querySelectorAll(".swiper-container");
		this.sliders = [];

		if (!this.containers) {
			return;
		}

		this.buildSliders();
	}

	buildSliders() {
		this.containers.forEach(slider => {
			const swiper = slider.querySelector(".swiper");
			const prev = slider.querySelector(".prev");
			const next = slider.querySelector(".next");

			this.sliders.push(new Swiper(swiper, {
				loop: true,
				grabCursor: true,
				slidesPerView: 1,
				speed: 500,
				spaceBetween: "4%",
				// effect: 'fade',
				// fadeEffect: {
				//     crossFade: true
				// },
				modules: [Navigation],
				navigation: {
					prevEl: prev,
					nextEl: next,
				}
			}));
		});
	}
}
