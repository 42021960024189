<template>
    <canvas id="canvas"></canvas>
</template>

<script>
import Assets from '../scene/Assets.js';
import World from '../scene/World.js';

export default {
  name: 'SceneWebgl',
  data() {
    return {
      loading: true,
      progress: 0,
    };
  },
  mounted() {
    const minLoadingTime = 2000;
    this.assets = new Assets(); // Create the Assets instance only once
    const startTime = Date.now();

    this.assets.onProgress = (progress) => {
      this.progress = progress;
      this.updateLoadingState();
    };

    this.assets.loadEndFunction = () => {
      const elapsed = Date.now() - startTime;
      const remainingTime = minLoadingTime - elapsed;

      if (remainingTime > 0) {
        setTimeout(() => {
          this.hideLoadingScreen();
        }, remainingTime);
      } else {
        this.hideLoadingScreen();
      }
    };

  },
  methods: {
    hideLoadingScreen() {
      this.world = new World(this.assets); // Create the World instance only once
      this.loading = false;
      this.updateLoadingState();
    },
    updateLoadingState() {
      this.$emit('update-loading', { loading: this.loading, progress: this.progress });
    },
    zoomIn() {
      if (this.world) {
        this.world.control.cameraTemp.y = this.world.control.camOffset.y;
        this.world.control.cameraTemp.z = this.world.control.camOffset.z;
        this.world.control.cameraTemp.x = this.world.control.camOffset.x;
      }
    },
  },
};
</script>

<style scoped>
</style>
