import * as THREE from "three";
import Stats from "stats.js";

export default class Renderer {
  constructor(world) {
    this.world = world;

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.world.canvas,
      antialias: true,
    });
    this.renderer.shadowMap.enabled = true;
    //this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.realisticLights = true;

    if (this.world.debugON) {
      this.stats = new Stats();
      document.body.appendChild(this.stats.dom);
    }

    this.localTick = this.tick.bind(this);
  }

  tick() {
    if (this.world.debugON) this.stats.begin();
    this.renderer.render(this.world.scene, this.world.camera);

    this.world.materials.uniforms.uTime.value = performance.now() * 0.001;

    // Point opacity
    this.world.materials.uniforms.uSpriteFade.value +=
      ((this.world.currentSprite.value === -1 ? 0 : 1) -
        this.world.materials.uniforms.uSpriteFade.value) *
      0.2;

    this.world.control.update();
    //this.world.animations.update()

    if (this.world.debugON) this.stats.end();
    window.requestAnimationFrame(this.localTick);
  }
}
