import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { EXRLoader } from "three/addons/loaders/EXRLoader.js";

import * as THREE from "three";

export default class Assets {
  constructor() {
    this.toLoad = 0;
    this.loaded = 0;
    this.loadEndFunction = null;
    this.onProgress = null;

    if (!Assets.instance) {
      this.instance = this;
    }

    Assets.instance = this;

    this.gltfLoader = new GLTFLoader();
    this.textureLoader = new THREE.TextureLoader();
    this.exrLoader = new EXRLoader();

    this.rotations = {
      1: [0.18, 0.08],
      2: [0.16, -0.13],
      3: [0.21, 0.1],
      4: [0.17, -0.13],
      5: [0.17, -0.29],
      6: [0.19, -0.3],
      7: [0.16, -0.11],
      8: [0.16, -0.58],
      9: [0.18, -0.06],
      10: [0.18, -0.06],
      11: [0.18, -0.06],
      12: [0.18, -0.06],
    };

    this.map = null;
    this.water = null;
    this.blueprint = null;
    this.animations = null;
    this.keepmaterial = null;

    this.furnitures = {
      train1: null,
      train2: null,
      tree1: null,
      tree2: null,
      bush: null,
    };

    // Textures
    this.textures = {
      color: null,
      water: null,
      watermap: null,
      clouds: null,
      blueprint: null,
      cloudShadow: null,
      background: null,
      subPoints: null,
    };

    // this.buildRaw();
    this.loadTextures();
    this.loadGLB();
  }

  getName(name) {
    for (let n in this.furnitures) {
      if (name.includes(n)) return n;
    }
  }

  // buildRaw() {
  //   const raw = {
  //     area: {},
  //     cam: {},
  //     furnitures: { ...this.furnitures },
  //   };
  //   for (let key in this.furnitures) {
  //     raw["furnitures"][key] = [];
  //   }
  //
  //   this.gltfLoader.load("./assets.glb", (gltf) => {
  //     for (let mesh of gltf.scene.children) {
  //       if (mesh.name.includes("area")) {
  //         console.log("area", mesh.name.slice(4, 6));
  //
  //         raw["area"][Number(mesh.name.slice(4, 6))] = {
  //           p: [mesh.position.x, mesh.position.y, mesh.position.z],
  //         };
  //       } else if (mesh.name.includes("cam")) {
  //         console.log("cam", mesh.name.slice(3, 5));
  //         raw["cam"][Number(mesh.name.slice(3, 5))] = {
  //           p: [mesh.position.x, mesh.position.y, mesh.position.z],
  //           r: [],
  //         };
  //       } else {
  //         raw["furnitures"][this.getName(mesh.name)].push([
  //           mesh.position.x,
  //           mesh.position.y,
  //           mesh.position.z,
  //           mesh.rotation.x,
  //           mesh.rotation.y,
  //           mesh.rotation.z,
  //           mesh.scale.x,
  //         ]);
  //       }
  //     }
  //
  //     // camera.rotation
  //     for (const id in raw.cam) {
  //       const t = new THREE.Vector3(
  //         raw.area[id].p[0],
  //         raw.area[id].p[1],
  //         raw.area[id].p[2]
  //       );
  //       const p = new THREE.Vector3(
  //         raw.cam[id].p[0],
  //         raw.cam[id].p[1],
  //         raw.cam[id].p[2]
  //       );
  //       // Step 1: Calculate direction from position to target
  //       const direction = new THREE.Vector3().subVectors(t, p);
  //       direction.normalize();
  //       const spherical = new THREE.Spherical();
  //       spherical.setFromVector3(direction);
  //       const pitch = spherical.phi - Math.PI / 2; // Rotation on X-axis (vertical)
  //       const yaw = spherical.theta; // Rotation on Y-axis (horizontal)
  //
  //       raw.cam[id].r = [pitch, yaw, 0];
  //     }
  //   console.log(JSON.stringify(raw));
  //   });
  // }

  updateProgress() {
    // Calculate the percentage of assets loaded
    const progress = Math.floor((this.loaded / this.toLoad) * 100);
    if (this.onProgress) {
      this.onProgress(progress); // Call the progress callback
    }
  }

  loadTextures() {
    this.toLoad += 1;
    this.textureLoader.load("./textures/hdri.webp", (texture) => {
      this.textures.background = texture;
      this.textures.background.mapping = THREE.EquirectangularReflectionMapping;
      this.textures.background.colorSpace = THREE.SRGBColorSpace;
      this.newLoad();
    });

    this.toLoad += 1;
    this.exrLoader.load("./textures/courtyard.exr", (texture) => {
      this.textures.hdri = texture;
      this.textures.hdri.mapping = THREE.EquirectangularReflectionMapping;
      this.textures.hdri.colorSpace = THREE.SRGBColorSpace;
      this.newLoad();
    });

    this.toLoad += 1;
    this.textureLoader.load("./textures/color.jpg", (texture) => {
      this.textures.color = texture;
      this.textures.color.flipY = false;
      this.textures.color.colorSpace = THREE.SRGBColorSpace;
      this.newLoad();
    });

    this.toLoad += 1;
    this.textureLoader.load("./textures/water.jpg", (texture) => {
      this.textures.water = texture;
      this.textures.water.wrapS = THREE.RepeatWrapping;
      this.textures.water.wrapT = THREE.RepeatWrapping;
      this.newLoad();
    });

    this.toLoad += 1;
    this.textureLoader.load("./textures/watermap.jpg", (texture) => {
      this.textures.watermap = texture;
      this.textures.watermap.wrapS = THREE.RepeatWrapping;
      this.textures.watermap.wrapT = THREE.RepeatWrapping;
      this.newLoad();
    });

    this.toLoad += 1;
    this.textureLoader.load("./textures/blueprint.jpg", (texture) => {
      this.textures.blueprint = texture;
      this.textures.blueprint.flipY = false;
      this.textures.blueprint.colorSpace = THREE.SRGBColorSpace;
      this.newLoad();
    });

    this.toLoad += 1;
    this.textureLoader.load("./textures/clouds.jpg", (texture) => {
      this.textures.clouds = texture;
      this.textures.clouds.wrapS = THREE.RepeatWrapping;
      this.textures.clouds.wrapT = THREE.RepeatWrapping;
      this.textures.clouds.colorSpace = THREE.SRGBColorSpace;
      this.newLoad();
    });

    this.toLoad += 1;
    this.textureLoader.load("./textures/cloudshadow.jpg", (texture) => {
      this.textures.cloudShadow = texture;
      this.textures.cloudShadow.wrapS = THREE.RepeatWrapping;
      this.textures.cloudShadow.wrapT = THREE.RepeatWrapping;
      this.textures.cloudShadow.colorSpace = THREE.SRGBColorSpace;
      this.newLoad();
    });
  }

  loadGLB() {
    // Furnitures
    this.toLoad += 1;
    this.gltfLoader.load("./furnitures.glb", (gltf) => {
      for (let mesh of gltf.scene.children) {
        this.furnitures[mesh.name] = mesh;
      }
      this.newLoad();
    });

    // Map
    this.toLoad += 1;
    this.gltfLoader.load("./map.glb", (gltf) => {
      this.map = gltf.scene.children.find((child) => child.name === "map");
      //
      // if (this.map) {
      //   this.map.traverse((child) => {
          // console.log(child.name, child.material);
      //   });
      // }

      this.water = gltf.scene.children.find((child) => child.name === "water");
      this.blueprint = gltf.scene.children.find(
        (child) => child.name === "blueprint"
      );
      this.blueprintOutline = gltf.scene.children.find(
        (child) => child.name === "blueprintoutline"
      );
      this.wateranex = gltf.scene.children.find(
        (child) => child.name === "wateranex"
      );
      this.vincigeo = gltf.scene.children.find(
        (child) => child.name === "vincilogo"
      );
      // console.log(this.vincigeo);
      this.vincigeo.children.forEach((child) => {
        if (
          child.name === "Logo_VINCI_Construction_-_fond_blanc" ||
          child.name === "Logo_VINCI_Construction_-_fond_blanc_1"
        ) {
          // console.log(child.name, child.material);

          const originalMaterial = child.material;
          const clonedMaterial = originalMaterial.clone();

          const foggedMaterial = new THREE.ShaderMaterial({
            uniforms: {
              uTime: { value: 0 },
              uTexture: { value: clonedMaterial.map },
            },
            vertexShader: `
              varying vec2 vUv;
              void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
              }
            `,
            fragmentShader: `
              uniform sampler2D uTexture;
              uniform float uTime;
              varying vec2 vUv;
              void main() {
                vec4 color = texture2D(uTexture, vUv);
                gl_FragColor = color;
              }
            `,
          });
          child.material = foggedMaterial;
        }
      });

      this.newLoad();
    });

    // Animated
    this.toLoad += 1;
    this.gltfLoader.load("./animated.glb", (gltf) => {
      this.animations = gltf;
      this.newLoad();
    });
  }

  newLoad() {
    this.loaded += 1;
    this.updateProgress();

    if (this.loaded === this.toLoad) {
      if (this.loadEndFunction) {
        this.loadEndFunction();
      }
    }
  }
}
