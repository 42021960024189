export const control = {
    panSpeed: 15, // vitesse de la caméra
    dragLerp: 3, // smooth du drag de camera
    swapLerp: 2, // smooth du click sur les points d'interets
    localPanRange: 1, // amplitudes du mouvement de caméra au mousemove
    localPointPanRange: 0.1, // amplitudes du mouvement de caméra au mousemove

    defaultRotY: -0.6,
    defaultRotX: -0.9,

    cameraMinZMove: 25,
    cameraMaxZMove: 45,
    cameraMinZMobileMove: 33,
    cameraMaxZMobileMove: 55,
    cameraMinXMove: -40,
    cameraMaxXMove: -15,
    cameraMinXMobileMove: -40,
    cameraMaxXMobileMove: -5,
};

// Interest points
export const interest = {
    spriteScale: 0.035,
    spriteYElevation: 1,
};
