<template>
  <div class="loading-overlay" :class="{ 'fade-out': isFadingOut }">
    <p class="percentage"><span class="percentage-value">{{ progress }}</span>%</p>
    <div class="progress-bar">
      <div class="progress" :style="{ width: progress + '%' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isFadingOut: false,
    };
  },
  watch: {
    progress(newProgress) {
      if (newProgress >= 100) {
        this.isFadingOut = true;
        setTimeout(() => {
          this.$emit('loading-complete');
        }, 1000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 10;
  font-family: "Vinci Sans Expanded", sans-serif;
  opacity: 1;
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
}

.percentage {
  position: absolute;
  bottom: 32px;
  right: 44px;
  font-size: 3.12rem;
  font-weight: bold;
  color: #002c71;
  margin: 0;
}

.percentage-value {
  font-size: 5rem;
  font-weight: bold;


  @media (max-width: 500px) {
    font-size: 3rem;
  }
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #ffffff;
}

.progress {
  height: 100%;
  background-color: #002c71;
  transition: width 0.3s ease;
}
</style>
